<template>
	<div class="my-account">
		<!-- <div class="header-menu va-row">
      <div class="va-row">
         <div class="flex container-ddh-menu xs12 with-bottom va-row">
            <div class="flex xs6 no-padding">
               <h1>Escolas</h1>
            </div>
            <div class="flex xs6 no-padding text-right">
               <br><br>
            
               <button
                  v-on:click="showSchool(emptySchool, 'add')"
                  class="btn btn-change">
                  ADICIONAR ESCOLA
               </button>
            </div>
         </div>
      </div>
      <div class="clearfix"></div>
   </div> -->

		<div class="header-menu va-row">
			<div class="flex md10 offset--md1" v-if="schoolInfo !== null">
				<div class="header-teacher">
					<div class="va-row">
						<div class="flex xs6 ddh-item">Dentro Educa</div>
						<div class="flex xs6 ddh-prof">{{ userCredential.name }}</div>
					</div>

					<div class="va-row">
						<div class="flex xs12 text-left school-name">
							<router-link :to="{ name: 'grades' }">
								<span class="back-circle">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 36 36"
									>
										<path
											fill="#4965bc"
											fill-rule="evenodd"
											stroke="#4965bc"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.6"
											d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
										/>
									</svg>
								</span>
							</router-link>
							{{ schoolInfo.name }}
						</div>
					</div>
				</div>
			</div>

			<div class="teacher-grade va-row" v-if="userCredential.user">
				<div class="flex md10 offset--md1">
					<div class="va-row">
						<div class="flex xs6 teacher-name">
							Professor(a) {{ userCredential.user.name }}
						</div>
						<div class="flex xs6 text-right">
							<button class="btn btn-primary">
								ÁREA DO ALUNO <span class="fa fa-external-link"></span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!gradeInfo">
			<h5>Ainda não existe registros aqui</h5>
		</div>

		<div class="body-teacher" v-if="gradeInfo !== null">
			<div class="va-row">
				<div class="flex md10 offset--md1">
					<div class="body-spacing">
						<div class="panel">
							<h4>
								{{ gradeInfo.name }} <span>•</span> Período
								{{ gradeInfo.period }} <span>•</span> {{ gradeInfo.year }}
							</h4>
							<hr />
							<div class="info-grade va-row">
								<div class="flex xs6 no-padding">
									{{ gradeInfo.student_count }} alunos cadastrados<br />
									{{ gradeInfo.size }} livros criados
								</div>
								<div class="flex xs6 text-right no-padding">
									<button class="btn btn-primary">IMPRIMIR LISTA</button>
								</div>
							</div>

							<div class="panel-gray">
								<div
									class="va-row"
									v-if="gradeInfo.status !== 'enviado para impressão'"
								>
									<div class="flex xs12 text-center">
										<router-link
											v-if="gradeInfo.students.length > 0"
											:to="{
												name: 'add-students',
												params: { grade: gradeInfo },
											}"
											class="btn btn-add"
											><span>+</span> ADICIONAR OU EXCLUIR ALUNOS</router-link
										>
										<router-link
											v-if="gradeInfo.students.length === 0"
											:to="{
												name: 'add-students',
												params: { grade: gradeInfo },
											}"
											class="btn btn-add"
											><span>+</span> ADICIONAR ALUNOS</router-link
										>
									</div>
								</div>

								<div class="va-row custom-teacher-table">
									<div class="flex xs12 header-table">
										<div class="va-row">
											<div class="flex xs6">Alunos</div>
											<div class="flex xs6 text-right">
												<b>Código do Aluno</b>
											</div>
										</div>
									</div>

									<div
										class="flex xs12 body-student"
										v-for="student in gradeInfo.students"
										:key="student.id"
									>
										<div class="va-row">
											<div class="flex xs7 student-name">
												{{ student.name }}
											</div>
											<div class="flex xs5">
												<div class="va-row">
													<div class="flex xs9 text-right">
														<div class="va-row book-covers">
															<div
																class="flex xs4 text-center"
																v-for="product in student.products"
																:key="product.id"
															>
																<img
																	:src="
																		'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
																		product.builder_uuid +
																		'?page=1&w=300'
																	"
																	alt=""
																/>
																<br />

																<!-- {{product.builder_uuid}}<br>
                              {{studentCredential.main_product.character_id}} -->

																<router-link
																	:to="{
																		name: 'preview',
																		params: {
																			product: {
																				builderCharacterId:
																					product.builder_uuid,
																				builderProductId:
																					gradeInfo.main_product.builder_id,
																				isStudent: false,
																				grade: grade,
																			},
																		},
																	}"
																	class="btn-micro-edit"
																>
																	VER LIVRO
																</router-link>

																<!-- <button class="btn-micro-edit">VER LIVRO</button> -->
															</div>

															<div
																class="flex xs4 text-center"
																v-if="student.products.length === 0"
															>
																<div class="empty-book">+</div>
																<br />
																<!-- {{}} -->
																<button
																	class="btn-micro-edit"
																	@click="
																		doLoginStudent(
																			student.username,
																			userCredential.school_id
																		)
																	"
																>
																	CRIAR LIVRO
																</button>
															</div>
														</div>
													</div>
													<div class="flex xs3 text-right student-code">
														{{ student.username }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								class="phrase-secure va-row"
								v-if="gradeInfo.status !== 'enviado para impressão'"
							>
								<div class="flex xs6">
									<b>Atenção: </b>
									<br />
									Para imprimir, é preciso que todos os alunos estejam com os
									livros criados.
								</div>
								<div class="flex xs6 text-right">
									<button class="btn" disabled="disabled">IMPRIMIR</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "Grade",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				userCredential: "userCredential",
				// ...
			}),
		},

		mounted() {
			const self = this;

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			let config = {
				headers: {
					Authorization: "Bearer " + this.userCredential.token,
				},
			};

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/schools/" +
						this.userCredential.school_id,
					config
				)
				.then((response) => {
					self.schoolInfo = response.data.data.school;
				})
				.catch((e) => {
					this.errors.push(e);
				});

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/grades/" +
						this.userCredential.grade_id[0],
					config
				)
				.then((response) => {
					self.gradeInfo = response.data.data.grade;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},

		props: ["grade"],

		data() {
			return {
				list: null,

				schoolInfo: null,
				gradeInfo: null,

				emptySchool: {
					name: "",
					address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},
					contact_name: "",
					contact_email: "",
					contact_phone: "",
					type: 0,
					project_id: 0,
				},

				emptyGrade: {
					role_id: "20",
					project_id: "1",
					name: "",
					grade_type_id: "",
					period_id: "",
					year: "",
					school_id: "",
					size: "",
					deadline: "",
				},

				schoolModalType: "view",
				schoolSelected: null,

				gradeModalType: "view",
				gradeSelected: null,

				files: "",
				show: false,
				// subscriptionsData: null,
				// addressEdit: {
				//   addressLabel: ''
				// },
				// subscriberEditing: false,
				// subscriptionSend: {
				//   'subscriptions': [],
				//   'address': this.addressEdit
				// },
				// percentCall: 0,
				// isCreation: true,

				// openAddressField: false,

				// cepSearch: false,
				// errors: []
			};
		},
		methods: {
			doLoginStudent(username, school_id) {
				const self = this;

				this.$validator.validateAll().then((result) => {
					let config = {
						headers: {
							Authorization: "Bearer " + this.userCredential.token,
						},
					};

					//console.log(self.login);
					let login = {
						username: username,
						school_id: school_id,
					};

					axios
						.post(
							process.env.VUE_APP_ROOT_API + "/students/student",
							login,
							config
						)
						.then(function (response) {
							let studentCredential = {
								token: response.data.data.token,
								user: response.data.data.user,
								main_product: response.data.data.main_product,
							};

							self.$store.commit("SAVE_STUDENT_CREDENTIALS", studentCredential);

							let routeData = self.$router.resolve({ name: "student-step-1" });
							window.open(routeData.href, "_blank");

							//self.$router.push({ name: 'student-step-1' })
						})
						.catch(function (error) {
							// console.log(error)
							this.errors.push(error);
						});
				});
			},

			showSchool(school, type) {
				this.schoolModalType = type;
				this.schoolSelected = school;
				this.$refs.largeModal.open();
			},

			showGrade(grade, school, type) {
				this.gradeModalType = type;
				this.gradeSelected = grade;
				this.schoolSelected = school;
				this.$refs.gradeModal.open();
			},

			closeModal() {
				this.$refs.largeModal.close();
				this.$refs.gradeModal.close();
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			deleteSchool(id, key) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.userCredential.token,
					},
				};

				if (confirm("Você deseja excluir este item?")) {
					axios
						.delete(process.env.VUE_APP_ROOT_API + "/schools/" + id, config)
						.then((response) => {
							self.list.splice(key, 1);
							//self.$router.push({ name: 'schools' })
						})
						.catch((e) => {
							this.errors.push(e);
						});
				}
			},

			saveAddress() {
				const self = this;

				let config = {
					onDownloadProgress: (progressEvent) => {
						let percentCompleted = Math.floor(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						this.percentCall = percentCompleted;
						// do whatever you like with the percentage complete
						// maybe dispatch an action that will update a progress bar or something
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line

						// this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
						this.addressEdit.nameRecipient =
							this.addressEdit.name + " " + this.addressEdit.lastname;
						this.subscriptionSend.address = this.addressEdit;

						if (this.isCreation) {
							axios
								.post(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							axios
								.put(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						}
					}
				});
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.phrase-secure {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #68596e;
		margin-top: 20px !important;
		// margin-bottom:20px !important;

		b {
			color: #68596e !important;
		}

		.btn {
			height: 65px;
			width: 100%;
			max-width: 357px;
			font-size: 14px;
			border-radius: 100px;
		}
	}

	.custom-teacher-table {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afa2ba;

		b {
			font-size: 14px !important;
		}

		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
		}
	}
	.teacher-flag {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		background-color: #9378f0;
		line-height: 30px;

		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}
	.book-covers {
		justify-content: flex-end;
	}
	.empty-book {
		width: 100%;
		border: 1px solid #afa2ba;
		height: 100%;
		height: 187px;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		display: flex;
		font-size: 25px;
	}
	.btn-micro-edit {
		font-family: "Nunito";
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.67px;
		text-align: center;
		color: #4965bc;
		background: none;
		border: none;
	}
	.student-name {
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: left;
		display: flex;
	}
	.student-code {
		font-family: Nunito;
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: 1.11px;
		text-align: right;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: right;
		display: flex;
	}

	.teacher-grade {
		background-color: white;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		.btn {
			width: 275px;
			height: 45px;
			border-radius: 100px;
			background-color: #9378f0;
			background-image: none;
			position: relative;

			span {
				font-size: 17px;
				position: absolute;
				top: 13px;
				right: 23px;
			}
		}

		.teacher-name {
			line-height: 45px;
		}
	}

	.panel-gray {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		overflow: hidden;

		.btn {
			border-radius: 100px;
			background-color: #4965bc;
			height: 65px;
			border-radius: 100px;
			background-color: #4965bc;
			padding-left: 70px;
			position: relative;
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;
			line-height: 40px;

			span {
				width: 45px;
				height: 45px;
				border-radius: 100px;
				background-color: #ffffff;
				font-size: 41px;
				display: inline-block;
				color: #4965bc;
				line-height: 43px;
				position: absolute;
				left: 8px;
				top: 8px;
			}
		}
	}

	.body-teacher {
		margin-top: 20px;

		.body-spacing .panel {
			padding: 10px 25px;

			h4 {
				margin-top: 10px;
				margin-bottom: 15px;
				font-family: Nunito;
				font-size: 20px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #66596e;
			}
		}
	}

	.body-student {
		border-bottom: 1px solid #dfdae4;
	}

	.info-grade {
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
	}

	.school-name {
		font-family: "Nunito";
		font-size: 40px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;

		.back-circle {
			width: 60px;
			height: 60px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			display: inline-block;
			text-align: center;
			border-radius: 30px;
		}
	}
	.my-account {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
	}

	.header-teacher {
		.ddh-item {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
		}

		.ddh-prof {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: right;
			color: #ffffff;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: #4965bc;
		margin-bottom: 20px;
		display: block;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	// .address-info{
	//   margin-bottom:15px;
	// }
	// .editing{
	//   margin-top:15px;
	// }
	// .radio-button-wrapper{
	//   padding: 14px 11px;
	//   margin-top: 10px;

	//   @media(max-width: 990px){
	//     padding: 20px 19px;
	//     margin-top: 10px;
	//   }
	// }
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	// Default of page

	// .my-data{

	//   /* Facebook bar */
	//   .facebook-tag{
	//     height: 65px;
	//     border-radius: 5px;
	//     background-color: #3a5998;
	//     color:white;
	//     padding: 14px 20px;
	//     margin-bottom: 20px;

	//     svg{
	//       font-size: 26px;
	//       margin-right: 0px;
	//       float: left;

	//       path{
	//         fill: #fff;
	//       }
	//     }

	//     .name{
	//       display: inline-block;
	//       margin-top: 1px;
	//       line-height: 37px;
	//     }

	//     .facebook-toggle{
	//       float: right;
	//       display: block;
	//       margin-top: 5px;
	//     }
	//   }

	// }

	// .change-password{
	//   border-radius: 10px;
	//   border: solid 2px #f0ebf5;
	//   background-color: #ffffff;
	//   padding: 24px 20px;
	//   position: relative;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 7px;
	//     font-family: 'Roboto';

	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//   }

	//   h5{
	//     font-size: 14px;
	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//     color: #afa2ba;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .floated-icon{
	//     float: left;
	//     width: 36px;
	//     height: 36px;
	//     font-size:30px;

	//     svg{
	//       position: relative;
	//       top: -5px;
	//       left: -3px;

	//       path{
	//         fill: #afa2ba;
	//       }
	//     }
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	// }

	// .my-data-table{
	//   h4{
	//     margin-top: 50px;
	//     margin-bottom: 20px;
	//   }
	// }

	// .fiscal-notes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   .body-spacing{
	//     padding: 30px 30px 10px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//     //  div:last-child{
	//     //   margin-bottom: 0px;
	//     // }
	//   }

	//   hr{
	//     border-top: solid 1px #f0ebf5;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   .company-code{
	//     text-transform: capitalize;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   // .panel:last-child{
	//   //   margin-bottom: 0px;
	//   // }
	// }

	// .address-boxes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   svg{
	//     path{
	//       fill: #afa2ba;
	//     }
	//   }

	//   .clube{
	//     svg{
	//     path{
	//         fill: #fff;
	//       }
	//     }
	//   }

	//   .body-spacing{
	//     padding: 30px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//   }

	//   hr{
	//     border-top: solid 2px #f0ebf5;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   .more-address{
	//     border-radius: 10px;
	//     border: solid 1px #f0ebf5;
	//     background-color: #ffffff;
	//   }

	//   .address-row{
	//     padding: 16px 0px;
	//     position: relative;
	//     border-bottom: solid 1px #f0ebf5;
	//     b{
	//       font-size: 16px;
	//       color: #000000;
	//     }
	//     :last-child{
	//       border-bottom: none;
	//     }

	//   }

	// }

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		// border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;
		box-shadow: 0 10px 25px 0 #e2ddf6;
		background-color: #ffffff;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
